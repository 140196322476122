@use "sass:meta" as ---hnobc2tmhw;@import '@financial-times/o-colors/main';
@import '@financial-times/o-typography/main';

// Display fonts
@for $i from 0 through 10 {
	.text-display--scale-#{$i} {
		@if $i == 10 {
			@include oTypographyDisplay(
				$scale: (
					'default': $i - 4,
					'M': $i,
				)
			);
		} @else if $i == 9 {
			@include oTypographyDisplay(
				$scale: (
					'default': $i - 3,
					'M': $i,
				)
			);
		} @else if $i == 8 {
			@include oTypographyDisplay(
				$scale: (
					'default': $i - 3,
					'M': $i,
				)
			);
		} @else if $i == 7 {
			@include oTypographyDisplay(
				$scale: (
					'default': $i - 2,
					'M': $i,
				)
			);
		} @else if $i == 2 or $i >= 4 {
			@include oTypographyDisplay(
				$scale: (
					'default': $i - 1,
					'M': $i,
				)
			);
		} @else {
			@include oTypographyDisplay($scale: $i);
		}
	}
}

// Sans fonts
.text-sans--scale--1 {
	@include oTypographySans($scale: -1);
}

.text-sans--scale-0 {
	@include oTypographySans($scale: 0);
}

.text-sans--scale-2 {
	@include oTypographySans($scale: 2);
}

.text-sans--scale-4 {
	@include oTypographySans($scale: 4);
}

.text-sans--scale-6 {
	@include oTypographySans($scale: 6);
}

.text-sans--scale-7 {
	@include oTypographySans($scale: 7);
}

.text-sans--scale-8 {
	@include oTypographySans($scale: 8);
}

// Colours
.text--color-black {
	color: oColorsByName('black');
}

.text--color-black-50 {
	color: oColorsByName('black-50');
}

.text--color-black-60 {
	color: oColorsByName('black-60');
}

.text--color-black-70 {
	color: oColorsByName('black-70');
}

.text--color-black-80 {
	color: oColorsByName('black-80');
}

.text--color-claret {
	color: oColorsByName('claret');
}

.text--color-mint {
	color: oColorsByName('mint');
}

.text--color-matisse {
	color: #0d4680;
	/* matisse is not yet implemented in o-colors, but used by Ads*/
}

.text--color-crimson {
	color: oColorsByName('crimson');
}

.text--color-teal {
	color: oColorsByName('teal');
}

.text--color-white {
	color: oColorsByName('white');
}

a:hover,
a:focus {
	:not(.text--style--no-active-state) {
		/* Currently below black-70 doesn't need to have active state */
		&.text--color-black,
		&.text--color-black-80,
		&.text--color-black-70,
		&.text--color-claret,
		&.text--color-matisse,
		&.text--color-crimson,
		&.text--color-teal {
			color: oColorsByName('black-60');
		}

		&.text--color-white {
			color: oColorsByName('white');
			opacity: 0.85;
		}
	}
}

// Weight
.text--weight-400 {
	font-weight: 400;
}

.text--weight-500 {
	font-weight: 500;
}

.text--weight-600 {
	font-weight: 600;
}

.text--weight-700 {
	font-weight: 700;
}

.text--transform-uppercase {
	text-transform: uppercase;
}

.text {
	margin: 0;
	padding: 0;
}

@mixin dark-mode-text($background-color) {
	.text--color-black,
	.text--color-black-80,
	.text--color-black-70,
	.text--color-black-60,
	.text--color-claret {
		color: oColorsByName('white');
	}

	a:hover,
	a:focus {
		:not(.text--style--no-active-state) {
			&.text--color-black,
			&.text--color-black-80,
			&.text--color-black-70,
			&.text--color-claret,
			&.text--color-white {
				color: oColorsMix(white, $background-color, 85);
				opacity: 1;
			}
		}
	}
}

;@include ---hnobc2tmhw.load-css("sass-embedded-legacy-load-done:269");