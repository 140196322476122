@use "sass:meta" as ---hnobc2tmhw;@import '@financial-times/o-colors/main';

.story-collection {
    grid-gap: oSpacingByName('s4');

    .layout__grid-content {
        padding: 0; // remove layout item padding to let grid-gap to add the paddings
    }

    &.layout-desktop__grid-container {
        margin: 0; // remove -1rem margins
    }
}

// Custom styles for FeaturedStory inside StoryCollection
.story-collection .featured-story {
    .featured-story-content {
        margin-bottom: 0; // remove margin applied in FeaturedStory for the content only
    }

    .headline {
        border-bottom: 1px solid oColorsByName('black-10');
        padding-bottom: $vertical-spacing * 2;

        @media (min-width: $desktop-breakpoint) {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}

;@include ---hnobc2tmhw.load-css("sass-embedded-legacy-load-done:305");