@use "sass:meta" as ---hnobc2tmhw;.links-promo {
	display: none;

	@media (min-width: $desktop-breakpoint) {
		height: 100%;
		grid-column: 4;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

;@include ---hnobc2tmhw.load-css("sass-embedded-legacy-load-done:304");