@use "sass:meta" as ---hnobc2tmhw;.podcast__article-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    & .podcast__content {
      margin-right: 20px;
    }

    & .podcast__image {
        flex-basis: 100px;
        flex-grow: 0;
        flex-shrink: 0;
        padding: 16px;
        padding-top: 0;
    }

    @media (max-width: $desktop-breakpoint) {
        margin-right: 0;
        & .podcast__image {
            max-width: 108px;
        }
    }

    & .podcast__suffix {
        padding-top: 10px;
    }
}

;@include ---hnobc2tmhw.load-css("sass-embedded-legacy-load-done:288");