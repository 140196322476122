@use "sass:meta" as ---hnobc2tmhw;@import 'o-lazy-load/main';

@include oLazyLoad($opts: (
	'placeholder': true, // .o-lazy-load-placeholder
	'placeholder-ratios': ((16, 9), (1, 1)), // e.g. .o-lazy-load-placeholder--16x9
	'transition': true // .o-lazy-load--transition
));

.image {
	width: 100%;
}

// These classes are only applied on IE
@media all and (-ms-high-contrast:none) {
	.image--width-40 {
		max-width: 40px;
	}

	.image--width-80 {
		max-width: 80px;
	}

	.image--width-280 {
		max-width: 280px;
	}

	.image--width-580 {
		//hack the full width 5050-right image isn't full width otherwise
		max-width: 620px;
	}

	.image--width-700 {
		max-width: 700px;
	}
}

;@include ---hnobc2tmhw.load-css("sass-embedded-legacy-load-done:264");