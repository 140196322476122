@use "sass:meta" as ---hnobc2tmhw;@import '@financial-times/o-icons/main';
@import '@financial-times/o-colors/main';

.icon {
	@include oIconsContentBaseStyles();
	vertical-align: middle;
}

.icon--premium {
	font-family: serif;
	font-size: 14px;
	line-height: 20px;
	margin-right: 4px;
	display: inline-block;
	background-color: black;
	text-align: center;
	color: white;
	width: 18px;
	height: 18px;
}

.icon--comments {
	@include oIconsContent($icon-name: 'speech', $color: oColorsByName('slate'), $size: 28, $include-base-styles: false);
}

.icon--audio:before {
	@include oIconsContent($icon-name: 'audio', $color: oColorsByName('slate'), $size: 28, $include-base-styles: false);
}

.icon--video:before {
	@include oIconsContent($icon-name: 'video', $color: oColorsByName('slate'), $size: 28, $include-base-styles: false);
}

.icon--play::before {
	@include oIconsContent($icon-name: 'play', $color: oColorsByName('white'), $size: 28, $include-base-styles: false);
}

.icon--audio, .icon--video, .icon--play {
	overflow: hidden;
	height: 12px;
	vertical-align: baseline;
	
	&:before {
		content:'';
		
		display: inline-block;
		vertical-align: middle;
		margin: -8px 0 0 -7px;
	}

	&.icon--scale-0 {
		height: 10px;

		&:before {
			width:21px;
			height:21px;
			margin: -11px 0 0 -4px;
		}
	}

	&.icon--scale-3 {
		height: 15px;

		&:before {
			width:32px;
			height:32px;
		}
	}

	&.icon--scale-4 {
		height: 17px;

		&:before {
			width:39px;
			height:39px;
			margin: -11px 0 0 -9px;
		}
	}

	&.icon--scale-5 {
		height: 19px;

		&:before {
			width:42px;
			height:42px;
			margin-top: -12px;
		}
	}

	&.icon--scale-6 , &.icon--scale-7 {
		height: 25px;

		&:before {
			width:54px;
			height:54px;
			margin:-15px 0 0 -12px;
		}
	}

	&.icon--scale-8 {
		height: 19px;

		&:before {
			width:42px;
			height:42px;
			margin-top: -12px;
		}
		
		@media (min-width: $desktop-breakpoint) {
			height: 34px;

			&:before {
				width:75px;
				height:75px;
				margin:-21px 0 0 -18px;
			}
		}
	}
}

.icon--opinion {
	overflow: hidden;
	height: 13px;
	vertical-align: baseline;
	
	&:before {
		content:'';
		@include oIconsContent($icon-name: 'speech-left', $color: oColorsByName('oxford'), $size: 28, $include-base-styles: false);
		display: inline-block;
		vertical-align: middle;
		margin: -8px 0 0 -5px;
	}

	&.icon--scale-0 {
		height: 10px;

		&:before {
			width:21px;
			height:21px;
			margin: -11px 0 0 -4px;
		}
	}

	&.icon--scale-3 {
		height: 15px;

		&:before {
			width:32px;
			height:32px;
		}
	}

	&.icon--scale-4 {
		height: 17px;

		&:before {
			width:39px;
			height:39px;
			margin-top: -11px;
		}
	}

	&.icon--scale-5 {
		height: 19px;

		&:before {
			width:42px;
			height:42px;
			margin-top: -12px;
		}
	}

	&.icon--scale-6 , &.icon--scale-7 {
		height: 25px;

		&:before {
			width:54px;
			height:54px;
			margin:-15px 0 0 -10px;
		}
	}

	&.icon--scale-8 {
		height: 19px;

		&:before {
			width:42px;
			height:42px;
			margin-top: -12px;
		}

		@media (min-width: $desktop-breakpoint) {
			height: 34px;

			&:before {
				width:75px;
				height:75px;
				margin:-21px 0 0 -10px;
			}
		}
	}
}

.icon--markets {
	overflow: hidden;
	height: 13px;
	vertical-align: baseline;

	&:before {
		content:'';
		@include oIconsContent($icon-name: 'data', $color: oColorsByName('teal-40'), $size: 23, $include-base-styles: false);
		display: inline-block;
		vertical-align: middle;
		margin: -5px 0 0 -5px;
	}

	&.icon--scale-0 {
		height: 10px;

		&:before {
			width:20px;
			height:20px;
			margin: -10px 0 0 -4px;
		}
	}

	&.icon--scale-3 {
		height: 15px;

		&:before {
			width:32px;
			height:32px;
			margin: -8px 0 0 -8px;
		}
	}

	&.icon--scale-4 {
		height: 17px;

		&:before {
			width:39px;
			height:39px;
			margin: -11px 0 0 -11px;
		}
	}

	&.icon--scale-5 {
		height: 19px;

		&:before {
			width:42px;
			height:42px;
			margin-top: -12px;
		}
	}

	&.icon--scale-6 , &.icon--scale-7 {
		height: 25px;

		&:before {
			width:54px;
			height:54px;
			margin:-15px 0 0 -13px;
		}
	}

	&.icon--scale-8 {
		height: 19px;

		&:before {
			width:42px;
			height:42px;
			margin-top: -12px;
		}

		@media (min-width: $desktop-breakpoint) {
			height: 34px;

			&:before {
				width:75px;
				height:75px;
				margin:-21px 0 0 -18px;
			}
		}
	}
}

@media (hover: none) {
	.icon--markets, .icon--opinion, .icon--video, .icon--audio {
		&:before {
			vertical-align: baseline;
		}
	}
}

@mixin dark-mode-icon {
	.icon--opinion:before {
		@include oIconsContent($icon-name: 'speech-left', $color: oColorsByName('white'), $size: null);
	}

	.icon--markets:before {
		@include oIconsContent($icon-name: 'data', $color: oColorsByName('white'), $size: null);
	}

	.icon--audio:before {
		@include oIconsContent($icon-name: 'audio', $color: oColorsByName('white'), $size: null);
	}

	.icon--video:before {
		@include oIconsContent($icon-name: 'video', $color: oColorsByName('white'), $size: null);
	}
}

;@include ---hnobc2tmhw.load-css("sass-embedded-legacy-load-done:268");