@use "sass:meta" as ---hnobc2tmhw;
.primary-opinion-story__wrapper {
	@include leftColumnSpan($left-column-span: 4);
	grid-column: span 1;
	margin-top: 1rem;
	text-align: center;

	.metadata {
		margin-top: $vertical-spacing;
	}

	.metadata__opinion {
		justify-content: center;
	}

	@media (min-width: 900px) {
		padding-right: $horizontal-spacing * 2;
		padding-left: $horizontal-spacing * 2;
	}
	
	@media (min-width: $desktop-breakpoint) {
		align-self: center;
		margin-top: 0;
		margin-left: $horizontal-spacing;
		.main-opinion-stories--theme-featured & {
			margin-left: 0;
		}
		.main-opinion-stories--theme-featured.main-opinion-stories--only-one & {
			margin-left: $horizontal-spacing;
		}
	}

	.main-opinion-stories--only-one & {
		@include leftColumnSpan();
		@media (min-width: 900px) {
			padding-right: $horizontal-spacing * 3;
			padding-left: $horizontal-spacing * 3;
		}
	}
}

.primary-opinion-story__teaser {
	.headline {
		margin-bottom: $vertical-spacing;
	}
}

.primary-opinion-story__image {
	@include rightColumnSpan($total-grid-columns: 9, $left-column-span: 4);
	order: -1;
	@media (min-width: $desktop-breakpoint) {
		order: unset;
	}

	.main-opinion-stories--only-one & {
		@include rightColumnSpan();
		@media (min-width: $desktop-breakpoint) {
			align-self: center;
			margin-right: $horizontal-spacing;
		}
	}
}

;@include ---hnobc2tmhw.load-css("sass-embedded-legacy-load-done:280");