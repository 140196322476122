@import '@financial-times/o-colors/main';

$system-code: 'next-home-page';

$section-vertical-spacing: 40px;

// Libraries setup
@import 'n-ui-foundations/mixins';
@import './adverts/adverts.scss';

@import 'o-forms/main';
@import 'n-syndication/main';

// Component styles
@import '/packages/components/styles/main.scss';
@import '../dotcom/pages/front/components/slices/AdvertSlice/AdvertSlice.scss';
@import '../dotcom/pages/front/components/slices/StoryGroupSlice/StoryGroupSlice.scss';
@import '../dotcom/pages/front/components/slices/InteractiveGraphicSlice/InteractiveGraphicSlice.scss';

html {
	font-size: unset;
}

a {
	color: unset;
	border-bottom: none;
	-webkit-text-decoration-color: unset;
	text-decoration-color: unset;
	text-decoration: none;
}

.n-syndication-modal {
	font-family: sans-serif;
	font-size: 0.8125em;

	a:not(.n-syndication-modal-close) {
		color: oColorsByName('teal');
		border-bottom: 0.25ex solid oColorsMix(teal, paper, 20);
		-webkit-text-decoration-color: oColorsMix(teal, paper, 20);
		text-decoration-color: oColorsMix(teal, paper, 20);
	}
}

// Remove <picture> height when used to render <img> & <source>
picture img {
	display: block;
}

// This is applied to spotlight second story teaser too (not for spotlight main story teaser)
.slice.top-stories-slice:not(.story-group-named-slice) {
	.primary-story__teaser {
		.headline {
			margin-bottom: 0;
		}
		.standfirst {
			display: none;
		}
	}
}

.slice.story-group-named-slice {
	.layout-desktop__grid:not(:first-child) {
		.primary-story__teaser {
			.headline {
				margin-bottom: 0;
			}
			.standfirst {
				display: none;
			}
		}
	}
}

.hidden {
	display: none;
}

@import 'n-exponea/main';
@include nExponea();

@import 'n-messaging-client/critical';
@import 'n-messaging-client/main';
