@use "sass:meta" as ---hnobc2tmhw;@import '@financial-times/o-colors/main';

.headline:not(.headline--color-white) :visited .text {
	color: oColorsByName('black-60');
}

.headline {
	.n-syndication-icon {
		margin-right: 4px;
	}

	&.headline--scale-8 .n-syndication-icon {
		border-radius: 28px;
		height: 28px;
		width: 28px;
	}

	&.headline--scale-6 .n-syndication-icon {
		border-radius: 26px;
		height: 26px;
		width: 26px;
	}

	&.headline--scale-5 .n-syndication-icon {
		border-radius: 24px;
		height: 24px;
		width: 24px;
	}

	&.headline--scale-2 .n-syndication-icon {
		border-radius: 18px;
		height: 18px;
		width: 18px;
	}

	&.headline--scale-0 .n-syndication-icon {
		border-radius: 16px;
		height: 16px;
		width: 16px;
	}
}

;@include ---hnobc2tmhw.load-css("sass-embedded-legacy-load-done:284");