@use "sass:meta" as ---hnobc2tmhw;@media (min-width: $desktop-breakpoint) {
	.set-piece-story-group--image {
			padding-right: 12rem;
			padding-left: 12rem;
	}
}

.set-piece-story-group--image {
	grid-row-start: 2;
	grid-column-start: 7;
	margin-bottom: 1.5rem;
}

.set-piece-story-group {
	grid-row-start: 1;
	grid-column-start: 1;


	.standfirst {
		margin-bottom: $vertical-spacing;
		.text {
			display: block;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}
	}

	@media (min-width: $desktop-breakpoint) {
		.primary-story__teaser {
			.standfirst {
				margin-bottom: 1.5rem;
				.text {
					display: block;
					padding-top: 0;
					padding-bottom: 0;
				}
			}

			.headline {
				margin-bottom: 1.5rem;
				padding-right: 12rem;
				padding-left: 12rem;
			}

			.story-group__title {
				margin-bottom: 0;
			}
		}
	}
}

.set-piece-story-group--primary-story {
	text-align: center;
	padding-right: $horizontal-spacing;
	padding-left: $horizontal-spacing;
	padding-top: $vertical-spacing;
	font-weight: 400;

	@media (min-width: $desktop-breakpoint) {
		padding-top: 0;
		.headline {
			padding-top: $vertical-spacing;
		}
	}
}

.set-piece-story-group--secondary-stories {
	align-self: end;
	grid-row-start: 4;
	grid-column-start: 1;

	.separator--solid {
		display: none;
	}

	.stacked-secondary-story--span-1, .stacked-secondary-story--span-2, .stacked-secondary-story--span-3, .stacked-secondary-story--span-4, .separator--solid {
		padding-right: $horizontal-spacing;

		.grid--s3-spacing {
			grid-gap: $vertical-spacing;
		}
	}

	@media (min-width: $desktop-breakpoint) {
		padding-top: $vertical-spacing;

		.separator--solid {
			display: flex;
		}

		&.grid-item--span-6 {
			grid-row-start: 2;
		}
	}

	.grid__story-group-wrapper--secondary {
		padding: 0 $horizontal-spacing $vertical-spacing $horizontal-spacing;

		.separator--solid {
			display: none;
		}

		@media (min-width: $desktop-breakpoint) {
			padding: $vertical-spacing 0 $vertical-spacing 0;
		}
	}
}

;@include ---hnobc2tmhw.load-css("sass-embedded-legacy-load-done:303");