@use "sass:meta" as ---hnobc2tmhw;@import '@financial-times/o-colors/main';
@import '@financial-times/o-typography/main';
@import '@financial-times/o-grid/main';

// Colours
@include oColorsSetColor('next-home-page/market-up', #9cd321);
@include oColorsSetColor('next-home-page/market-down', #ff767c);

// Use cases
@include oColorsSetUseCase('next-home-page/market-up', (
	'border': 'next-home-page/market-up',
	'text': 'next-home-page/market-up'
));
@include oColorsSetUseCase('next-home-page/market-down', (
	'border': 'next-home-page/market-down',
	'text': 'next-home-page/market-down'
));

.markets-data-wrapper {
	@include oTypographySans($scale: -1, $weight: 'semibold');
	background-color: oColorsByName('slate');
	height: 39px;
	width: 100%;
}

.markets-data {
	padding-top: 10px;
	padding-bottom: 10px;
	color: oColorsByName('white');
	display: flex;
	justify-content: space-between;

	& > :last-child {
		display: none;

		@include oGridRespondTo(L) {
			display: block;
		}
	}
}

.markets-data__item-link {
	color: inherit;
	text-decoration: none;
	&:hover,
	&:focus {
		color: inherit;
	}
}

.markets-data__items {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	justify-content: space-between;
	width: 100%;

	@include oGridRespondTo(L) {
		width: 80%;
	}
}

.markets-data__item {
	@include oGridRespondTo($until: S) {
		&:nth-child(n+3) {
			display: none;
		}
	}

	@include oGridRespondTo($until: M) {
		&:nth-child(n+4) {
			display: none;
		}
	}

	@include oGridRespondTo($until: L) {
		&:nth-child(n+5) {
			display: none;
		}
	}

	@include oGridRespondTo($until: XL) {
		&:nth-child(n+6) {
			display: none;
		}
	}
}

.markets-data__item-name {
	margin-right: 5px;
}

.markets-data__item-change {
	border: 1px solid oColorsByUsecase(page, background);
	border-radius: 3px;
	padding: 0 4px;
}

.markets-data__item-change--up {
	border-color: oColorsByUsecase('next-home-page/market-up', 'border');
	color: oColorsByUsecase('next-home-page/market-up', 'text');
}

.markets-data__item-change--down {
	border-color: oColorsByUsecase('next-home-page/market-down', 'border');
	color: oColorsByUsecase('next-home-page/market-down', 'text');
}

@media print {
	.markets-data-wrapper {
		display: none;
	}
}

;@include ---hnobc2tmhw.load-css("sass-embedded-legacy-load-done:282");