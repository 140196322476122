@use "sass:meta" as ---hnobc2tmhw;@import '@financial-times/o-colors/main';

@mixin leftColumnSpan($left-column-span: 5) {
	@media (min-width: $desktop-breakpoint) {
		grid-column: span $left-column-span;
		/* For IE11 */
		grid-column-start: 1;
		grid-column-end: $left-column-span + 1;
	}
}

@mixin rightColumnSpan($total-grid-columns: 12, $left-column-span: 5) {
	@media (min-width: $desktop-breakpoint) {
		grid-column: span ($total-grid-columns - $left-column-span);
		 /* For IE11 */
		grid-column-start: $left-column-span + 1;
		grid-column-end: $total-grid-columns + 1;
	}
}

.spotlight-story {
	@media (min-width: $desktop-breakpoint) {
		// Spotlight component's full width grid need to have the same margins as layout-desktop__grid-container
		// otherwise when the grid width is devided by the same number, the column width is different
		.grid--12-columns {
			margin-left: -$horizontal-spacing;
			margin-right: -$horizontal-spacing;
		}
	}

	.spotlight-grid--secondary-story {
		padding: 0 $horizontal-spacing;
		@media (min-width: $desktop-breakpoint) {
			padding-left: $horizontal-spacing;
			padding-right: $horizontal-spacing;
		}
	}
	.spotlight-grid--primary-story,
	&.spotlight-story--only-one  {
		.spotlight-story-main__teaser {
			padding: 0 $horizontal-spacing;
			@media (min-width: $desktop-breakpoint) {
				padding: 0;
			}
		}
	}

	.slice-heading {
		display: block;
		margin-top: -20px;
	}

	.separator--solid {
		margin: $vertical-spacing 0;
	}

	@media (min-width: $desktop-breakpoint) {
		.separator--solid {
			display: none;
		}
	}
}

// Featured theme custom styles
.spotlight-story--theme-featured {
	
	.headline .headline-indicator {
		// force the same icon size (icon--scale-5) for all stories
		> span {
			height: 19px;

			&:before {
				width:42px;
				height:42px;
				margin-top: -12px;
			}
		}
	}

	// Main Story
	.spotlight-grid--primary-story {
		@media (min-width: $desktop-breakpoint) {
			margin-left: $horizontal-spacing;
		}
	}

	.spotlight-grid--primary-story,
	&.spotlight-story--only-one {
		background-color: oColorsByName('wheat');
		padding-bottom: $vertical-spacing * 2;

		// Display the indicator separated from the title
		.headline .headline-indicator .icon {
			padding: 10px;
			display: block;
		}

		@media (min-width: $desktop-breakpoint) {
			padding-bottom: 0;
		}
	}

	// Second Story
	.spotlight-grid--secondary-story {
		padding-bottom: $vertical-spacing * 2;
		padding-top: $vertical-spacing * 3; // set padding because separator is hidden

		.separator--solid {
			display: none;
		}


		@media (min-width: $desktop-breakpoint) {
			padding-bottom: 0;
			padding-top: 0;
			padding-right: $horizontal-spacing;
			text-align: center;
			justify-content: center;
			
			.grid--fullHeight {
				align-content: center;
			}
			.metadata__opinion {
				justify-content: center;
			}

			// Display the indicator separated from the title (only for desktop)
			.headline .headline-indicator .icon {
				padding: 20px;
				display: block;
			}
		}
	}
}

.spotlight-grid--primary-story {
	@include leftColumnSpan($left-column-span: 10);
	height: 100%;
	@media (min-width: $desktop-breakpoint) {
		margin-right: $horizontal-spacing;

		.spotlight-story--only-one & {
			margin-right: 0;
		}
	}
}

.spotlight-grid--secondary-story {
	@include rightColumnSpan($total-grid-columns: 12, $left-column-span: 10);
	height: 100%;
	@media (min-width: $desktop-breakpoint) {
		border-left: solid oColorsByName('black-10') 1px;
		padding-left: $horizontal-spacing;
	}
}

;@include ---hnobc2tmhw.load-css("sass-embedded-legacy-load-done:277");